import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import Story from "../components/story"
import Layout from "../components/layout"
import Img from "gatsby-image"


const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
}

const BlockContent = require("@sanity/block-content-to-react")

export const data = graphql`
  query($slug: String!, $slugOfCategory: String!) {
    post: sanityPost(slug: { current: { eq: $slug } }) {
      title
      people
      slug {
        current
      }
      designation
      author {
        name
        bio
      }
      category {
        title
        slug {
          current
        }
      }
      mainImage {
        asset {
          fluid {
            src
            base64
            srcSetWebp
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawBody
    }
    similarStories: allSanityPost(
      filter: { category: { slug: { current: { eq: $slugOfCategory } } } }
      limit: 3
      sort: { fields: publishedAt, order: DESC }
    ) {
      nodes {
        title
        slug {
          current
        }
        publishedAt(formatString: "MM/DD/YYYY")
        category {
          title
          slug {
            current
          }
        }
        people
        designation
        excerpt
      }
    }
  }
`

export default function ({ data: { post, similarStories } }) {
  // Create an arrary of similar stories without the same story
  // Stories are similar if they belong to the same category
  const currentlyReading = post.slug.current
  const similarStoriesWithoutSameStory = similarStories.nodes.filter(
    story => story.slug.current !== currentlyReading
  )
  const [twitterShareUrl, setTwitterShareUrl] = useState(null)
  const [facebookShareUrl, setfacebookShareUrl] = useState(null)

  useEffect(() => {
    setTwitterShareUrl(
      `https://twitter.com/intent/tweet?url=${window.location.href}`
    )
    setfacebookShareUrl(
      `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}/`
    )
    return () => {}
  }, [])

  return (
    <Layout>
      <section className="lg:flex items-end bg-gray-200 mt-8 py-16">
        <div className="lg:w-1/2 lg:pr-8">
          <h1 className="section-head">{post.title}</h1>
          {post.people !== null ? (
            <div>
              <p className="lg:mt-16 mt-8 text-xs uppercase font-medium">
                Story Of
              </p>
              <p className="capitalize lg:mt-2 mt-1">
                <span className="font-medium">{post.people}</span> |{" "}
                <span className="text-sm">{post.designation}</span>
              </p>
            </div>
          ) : (
            ""
          )}
          {post.author !== null ? (
            <div className="capitalize mt-4 lg:mt-8">
              <p className="">
                <span className="text-base">Author:</span>{" "}
                <span className="font-medium">{post.author.name}</span>
              </p>
              <p className="text-sm">{post.author.bio}</p>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="lg:w-1/2 lg:pl-8 mt-8 lg:mt-0">
          <Img
            fluid={post.mainImage.asset.fluid}
            alt={post.title}
            draggable={false}
          />
        </div>
      </section>
      <section
        className="sanity-block leading-relaxed"
        style={{ marginTop: "0", background: "white" }}
      >
        <BlockContent
          blocks={post._rawBody}
          imageOptions={{ w: 320, h: 240, fit: "max" }}
          projectId="hdf6d0e0"
          dataset="production"
          serializers={serializers}
        />
      </section>
      <div className="mt-4 px-4 sm:px-8 font-semibold text-sm uppercase flex justify-start border-t pt-4 border-gray-200">
        <h4 className="inline-block text-blue-700">Share this story</h4>
        <div className="ml-4 flex justify-start">
          <a
            href={facebookShareUrl}
            className="block px-4 relative"
            title="Facebook"
          >
            <svg
              className="fill-current text-gray-700 hover:text-red-700 inline-block text-base h-5 w-5 transform hover:scale-110"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path>
            </svg>
          </a>
          <a
            className="block px-4 relative"
            href={twitterShareUrl}
            title="Twitter"
          >
            <svg
              className="fill-current text-gray-700 hover:text-red-700 inline-block text-base h-5 w-5 transform hover:scale-x-110"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
            </svg>
          </a>
        
        </div>
      </div>
      {similarStoriesWithoutSameStory.length > 0 ? (
        <div className="mt-16 -mb-8 px-4 sm:px-8">
          <h4 className=" -mb-4 text-red-700 section-head text-lg">
            Read Similar Stories
          </h4>
          {similarStoriesWithoutSameStory.map(story => (
            <Story
              title={story.title}
              slug={story.slug.current}
              mainImage={story.mainImage}
              excerpt={story.excerpt}
              date={story.publishedAt}
              category={story.category}
              people={story.people}
              designation={story.designation}
              key={story.slug.current}
              disableLink={false}
            />
          ))}
        </div>
      ) : (
        ""
      )}
      <Link
        className="mt-16 inline-block  hover:underline mx-4 sm:mx-8"
        to="/stories"
      >
        &larr; View all Stories
      </Link>
    </Layout>
  )
}