import React from "react"
import { Link } from "gatsby"

export default function Story({
  title,
  slug,
  excerpt,
  date,
  people,
  designation,
  category,
  disableLink,
}) {
  return (
    <div className="mt-12 mb-16 single-story-section">
      <h2 className="">
        <Link
          to={`/stories/${slug}`}
          className="hover:text-red-700 text-xl md:text-2xl font-normal hover:underline leading-none"
        >
          {title}
        </Link>
        <p className="text-gray-700 text-xs sm:text-sm">
          {" "}
          {date ? date : ""} {date && people ? " | " : " "}{" "}
          {people ? "  " + people + ", " + designation : ""}{" "}
          {/* {category !== null ? (
            <Link
              className="inline-block md:inline hover:bg-red-800 bg-blue-700 rounded-full px-2 text-white"
              to={`/stories/${
                category.slug.current ? category.slug.current : ""
              }`}
            >
              {category.title}
            </Link>
          ) : (
            ""
          )} */}
          {category !== null && !disableLink ? (
            <Link
              className="inline-block md:inline hover:bg-red-800 bg-blue-700 rounded-full px-2 text-white"
              to={`/stories/${
                category.slug.current ? category.slug.current : ""
              }`}
            >
              {category.title}
            </Link>
          ) : category !== null ? (
            <span className="inline-block md:inline bg-blue-700 rounded-full px-2 text-white">
              {category.title || null}
            </span>
          ) : null}
        </p>
      </h2>
      {/* <p>Story Of: {} </p> */}
      <p className="mt-1 text-base md:text-lg">{excerpt}</p>
    </div>
  )
}
